import { ActionContext } from "vuex";
import { State } from "../state";
import { SiteConfigState } from "./state";
import {
  commitSetDemoMode,
  commitSetProjectTimeZone,
  commitSetSiteConfigs,
} from "./mutations";
import { getStoreAccessors } from "typesafe-vuex";
import { backend } from "@/api/backend";
import {
  commitAddNotification,
  commitRemoveNotification,
} from "@/store/main/mutations";
import { SiteConfig } from "@/interfaces/site_config";

// Pass in the state management context
type MainContext = ActionContext<SiteConfigState, State>;

export const actions = {
  async actionGetSiteConfigs(context: MainContext): Promise<void> {
    if (context.rootState.siteConfig.siteConfigurations.length > 0) {
      // We've likely already fetched this result, return early.
      return;
    }
    try {
      const response = await backend.getSiteConfig(
        context.rootState.main.token
      );
      if (response) {
        commitSetSiteConfigs(context, response.data);
      }

      // Check if demo mode is in site config.
      let demoMode = response.data.some(
        (config: SiteConfig) =>
          config.name === "demo_mode" && config.val === "true"
      );

      if (!demoMode) {
        try {
          const demoModeResponse = await backend.getProjectDemoMode(
            context.rootState.main.token
          );
          demoMode = demoModeResponse.data;
        } finally {
          demoMode = false;
        }
      }

      commitSetDemoMode(context, demoMode);
    } catch (error) {
      console.log(`Getting site config failure`);
      console.log(error);
      commitAddNotification(context, {
        content: "Error getting site configuration",
        color: "error",
      });
    }
  },
  async actionDeleteSiteConfig(
    context: MainContext,
    payload: { id: number }
  ): Promise<void> {
    try {
      const response = await backend.deleteSiteConfig(
        context.rootState.main.token,
        payload.id
      );
      if (response) {
        await dispatchActionGetSiteConfigs(context);
      }
    } catch (error) {
      console.log(`Remove site configuration failure`);
      console.log(error);
      commitAddNotification(context, {
        content: "Failed to remove site configuration",
        color: "error",
      });
    }
  },
  async actionCreateSiteConfig(
    context: MainContext,
    siteConfig: SiteConfig
  ): Promise<void> {
    try {
      const response = await backend.createSiteConfig(
        context.rootState.main.token,
        siteConfig
      );
      if (response) {
        await dispatchActionGetSiteConfigs(context);
      }
    } catch (error) {
      console.log(`Create site config failure`);
      console.log(error);
      commitAddNotification(context, {
        content: "Failed to create site config",
        color: "error",
      });
    }
  },
  async actionUpdateSiteConfig(
    context: MainContext,
    payload: { id: number; siteConfig: SiteConfig }
  ): Promise<void> {
    const loadingNotification = { content: "Updating", showProgress: true };
    commitAddNotification(context, loadingNotification);
    try {
      const response = (
        await Promise.all([
          await backend.updateSiteConfig(
            context.rootState.main.token,
            payload.id,
            payload.siteConfig
          ),
          await new Promise<void>((resolve) =>
            setTimeout(() => resolve(), 500)
          ),
        ])
      )[0];
      if (response) {
        await dispatchActionGetSiteConfigs(context);
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: `Updated ${payload.siteConfig.name}`,
          color: "success",
        });
      }
    } catch (error) {
      console.log(`Update site config failure`);
      console.log(error);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Failed to update site config",
        color: "error",
      });
    }
  },
  async getProjectTimeZone(context: MainContext): Promise<void> {
    const response = await backend.getProjectTimeZone(
      context.rootState.main.token
    );
    commitSetProjectTimeZone(context, response.data);
  },
};

const { dispatch } = getStoreAccessors<SiteConfigState, State>("");

export const dispatchActionGetSiteConfigs = dispatch(
  actions.actionGetSiteConfigs
);
export const dispatchActionDeleteSiteConfig = dispatch(
  actions.actionDeleteSiteConfig
);
export const dispatchActionCreateSiteConfig = dispatch(
  actions.actionCreateSiteConfig
);
export const dispatchActionUpdateSiteConfig = dispatch(
  actions.actionUpdateSiteConfig
);
export const dispatchActionGetProjectTimeZone = dispatch(
  actions.getProjectTimeZone
);
